import * as yup from "yup";

const formikSchema = {
  initialValues: {
    lineItemsFile: "",
    vendorId: "",
  },
  validationSchema: yup.object({
    lineItemsFile: yup.mixed().required(),
    vendorId: yup.string().required(),
  }),
};

export default formikSchema;
