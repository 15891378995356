import {
  Button,
  downloadFile,
  Dropdown,
  FileUpload,
  FormikSubmit,
  FormWrapper,
  FullPageLoad,
  routing,
  useAxios,
  sortAndFormatOptions,
} from "common";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import formikSchema from "./formikSchema";
import { Container } from "./styles";

const ChangeFile = () => {
  const { brandId } = useParams();
  const { response } = useAxios({
    callOnLoad: { method: "GET", url: `/brands/${brandId}` },
  });
  const { response: vbRes } = useAxios({
    callOnLoad: { method: "GET", url: "/vendorBrands" },
  });
  const { callAxios: getSampleFile, loading: loadingSampleFile } = useAxios({
    onComplete: ({ data: sampleFile }) => {
      downloadFile(sampleFile);
    },
  });

  const navigate = useNavigate();

  const goBack = () => navigate(routing.brands.root);

  const { callAxios, loading } = useAxios({
    onComplete: goBack,
    alertSuccess: "File Uploaded Successfully!",
  });

  if (!response || !vbRes) return <FullPageLoad fillWidth />;

  const { data: brand } = response;
  const {
    data: { data: vendors },
  } = vbRes;
  return (
    <Container>
      <h1>{brand.name}</h1>
      <h3>Upload Brand Items</h3>
      <Formik
        {...formikSchema}
        onSubmit={(values) => {
          const data = new FormData();
          data.append("itemsExcel", values.lineItemsFile);
          data.append("vendorId", values.vendorId);
          callAxios({
            method: "POST",
            url: `/brands/${brandId}/upload`,
            data,
            headers: {
              "Content-Type": "blob",
            },
          });
        }}
      >
        <FormWrapper>
          <Form>
            <Field
              name="vendorId"
              component={Dropdown}
              label="Vendor"
              required
              options={sortAndFormatOptions({
                list: vendors,
                valueKey: "id",
                labelKey: (v) =>
                  v.id === brand.defaultVendorId
                    ? `***MAIN: ${v.name}`
                    : v.name,
              })}
            />
            <Field name="lineItemsFile" component={FileUpload} />
            <Button
              loading={loadingSampleFile}
              secondary
              onClick={() => {
                getSampleFile({
                  method: "GET",
                  url: "/brands/downloadSampleExcel",
                  responseType: "blob",
                });
              }}
            >
              Download Sample
            </Button>
          </Form>
          <FormikSubmit loading={loading}>Upload File</FormikSubmit>
        </FormWrapper>
      </Formik>
    </Container>
  );
};

export default ChangeFile;
